<template>
    <transition name="fade">
        <div v-show="isShowTooltip" class="tooltip-wrapper" :style="cssProp">
            <div class="tooltip" v-html="content"></div>
            <div class="triangle" />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Tooltip',
    methods: {
        //如無法顯示tips，請在顯示tips的icon的節點生成後，在頁面元件中使用$reloadTips重新綁定節點
        showToolTip: function () {
            const tool = event.target
            this.content = tool.getAttribute('tooltip')
            if (!this.content) return
            this.isShowTooltip = true
            this.$setGaEvent('showTooltip', 'show-Tooltip')
            this.$nextTick(() => {
                if (tool.hasAttribute('tooltip-touch'))
                    this.$addAllClass(this.$el, 'touch')
                else this.$removeAllClass(this.$el, 'touch')

                const docWidth = this.$getDocumentWidth() - 20
                const tipWidth = this.$el.offsetWidth
                const tipHeight = this.$el.offsetHeight
                const toolLeft = tool.getBoundingClientRect().left
                const toolTop = tool.getBoundingClientRect().top
                const toolWidth = tool.offsetWidth
                const toolHeight = tool.offsetHeight
                let posLeft = toolLeft + toolWidth / 2 - 18
                let posTop = toolTop - tipHeight
                let trianglePosLeft = 10
                let trianglePosTop = 0
                let triangleRotate = 0
                if (posLeft + tipWidth > docWidth) {
                    posLeft = toolLeft + toolWidth - tipWidth
                    trianglePosLeft = tipWidth - 18
                }
                if (posTop < 0) {
                    posTop = toolTop + toolHeight + 8
                    trianglePosTop = -tipHeight
                    triangleRotate = 180
                }

                this.triangleLeft = trianglePosLeft
                this.triangleTop = trianglePosTop
                this.triangleRotate = triangleRotate
                this.left = posLeft
                this.top = posTop
                window.addEventListener('scroll', this.hideToolTip, true)
            })
        },
        hideToolTip: function () {
            this.isShowTooltip = false
        },
        renewTooltips: function () {
            this.$nextTick(() => {
                let tools = document.querySelectorAll('[tooltip]')
                tools.forEach((tool) => {
                    tool.addEventListener('mouseenter', this.showToolTip)
                    tool.addEventListener('mouseleave', this.hideToolTip)
                    if (!tool.hasAttribute('tooltip-touch')) {
                        tool.addEventListener('click', this.hideToolTip)
                    }
                })
                this.$store.commit('set', {
                    isReloadTips: false
                })
            })
        }
    },
    computed: {
        cssProp: function () {
            return {
                '--tooltip-top': `${this.top}px`,
                '--tooltip-left': `${this.left}px`,
                '--tooltip-triangle-top': `${this.triangleTop}px`,
                '--tooltip-triangle-left': `${this.triangleLeft}px`,
                '--tooltip-triangle-rotate': `rotate(${this.triangleRotate}deg)`
            }
        },
        isReloadTips: function () {
            return this.$store.state.isReloadTips
        }
    },
    watch: {
        '$route.path': {
            handler: function () {
                //從API拿下拉選項時的延遲會造成Filter區有ToolTips選項無法即時渲染
                const _this = this
                setTimeout(function () {
                    _this.renewTooltips()
                }, 600)
            },
            deep: true,
            immediate: true
        },
        isShowTooltip: function (newVal) {
            if (!newVal) {
                window.removeEventListener('scroll', this.hideToolTip, true)
            }
        },
        isReloadTips: function (val) {
            if (val) {
                this.renewTooltips()
            }
        }
    },
    data() {
        return {
            isShowTooltip: false,
            triangleTop: 0,
            triangleLeft: 10,
            triangleRotate: 0,
            content: '?',
            top: 0,
            left: 0
        }
    },
    unmounted() {
        this.$nextTick(() => {
            let tools = document.querySelectorAll('[tooltip]')
            tools.forEach((tool) => {
                tool.removeEventListener('mouseenter', this.showToolTip)
                tool.removeEventListener('mouseleave', this.hideToolTip)
                if (!tool.hasAttribute('tooltip-touch')) {
                    tool.removeEventListener('click', this.hideToolTip)
                }
            })
        })
    }
}
</script>

<style lang="scss" scoped>
.tooltip-wrapper {
    display: inline-block;
    position: fixed;
    top: var(--tooltip-top);
    left: var(--tooltip-left);
    z-index: 11;

    .tooltip {
        min-width: 20px;
        min-height: 12px;
        max-width: 50vw;
        background: rgba(0, 0, 0, 0.6);
        padding: 8px 10px;
        border-radius: 5px;
        color: $primary-white;
        font-size: 12px;
        word-break: break-all;
        text-align: center;
        line-height: 14px;
        cursor: default;
        :deep(div) {
            width: 100%;
            text-align: left;
            &:not(:last-child) {
                margin-bottom: 3px;
            }
        }
    }

    .triangle {
        position: relative;
        top: var(--tooltip-triangle-top);
        left: var(--tooltip-triangle-left);
        transform: var(--tooltip-triangle-rotate);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 6px 0 6px;
        border-color: rgba(0, 0, 0, 0.6) transparent transparent transparent;
    }

    @media (hover: none) and (pointer: coarse) {
        &:not(.touch) {
            display: none;
        }
    }
}
</style>
